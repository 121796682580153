import { types } from "./type"
import API from "../api"
import { message } from "antd"
const {
  changeCustomerStatus,
  addCustomer,
  editCustomer,
  customerList,
  customerActives,
} = API

export const CustomerList = callback => dispatch => {
  customerList()
    .then(res => {
      if (res.data.success === true) {
        callback(false)
        dispatch({
          type: types.CUSTOMER_LIST,
          customerList: res.data.data,
        })
      } else {
        message.error({ content: `${res.data.message}`, duration: 2 })
        callback(false)
      }
    })
    .catch(err => {
      callback(false)
    })
}

export const CustomerActive = callback => dispatch => {
  customerActives()
    .then(res => {
      if (res.data.success === true) {
        callback(false)
        dispatch({
          type: types.CUSTOMER_ACTIVE,
          customerActive: res.data.data,
        })
      } else {
        message.error({ content: `${res.data.message}`, duration: 2 })
        callback(false)
      }
    })
    .catch(err => {
      callback(false)
    })
}

export const AddCustomer = (data, callback) => dispatch => {
  dispatch({ type: types.SHOW_LOADING, loading: true })
  addCustomer(data)
    .then(res => {
      if (res.data.status === 200) {
        callback(true)
        message.success({ content: `Customer Added`, duration: 2 })
        dispatch({
          type: types.ADD_CUSTOMER,
          newCustomerData: { ...res.data.data, status: 1 },
        })
      } else {
        message.error({ content: `${res.data.message}`, duration: 2 })
        callback(false)
      }
      dispatch({ type: types.SHOW_LOADING, loading: false })
    })
    .catch(err => {
      callback(false)
    })
}

export const EditCustomer = (data, callback) => dispatch => {
  dispatch({ type: types.SHOW_LOADING, loading: true })
  editCustomer(data)
    .then(res => {
      if (res.data.success === true) {
        callback(true)
        message.success({ content: `Customer Edited`, duration: 2 })
        dispatch({
          type: types.EDIT_CUSTOMER,
          updateCustomerData: data,
        })
      } else {
        message.error({ content: `${res.data.message}`, duration: 2 })
        callback(false)
      }
      dispatch({ type: types.SHOW_LOADING, loading: false })
    })
    .catch(err => {
      callback(false)
      message.error({ content: `${err.message}`, duration: 2 })
    })
}

export const ChangeCustomerStatus = data => dispatch => {
  dispatch({ type: types.SHOW_LOADING, loading: true })
  changeCustomerStatus(data)
    .then(res => {
      if (res.data.success === true) {
        dispatch({
          type: types.CHANGE_CUSTOMER_STATUS,
          customer_status: data.status,
          customerId: data.customerId,
        })
        //   navigate("/dashboard")
      }
      dispatch({ type: types.SHOW_LOADING, loading: false })
    })
    .catch(err => {
      message.error({ content: `${err}`, duration: 2 })
    })
}
