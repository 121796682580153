import React, { Component, useState, useEffect } from 'react'
import {
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Row,
  Col,
  Button,
  AutoComplete
} from 'antd'
import Footer from '../../../components/layout-components/modal/Footer'
import { connect } from 'react-redux'
import { AddCustomer, EditCustomer } from '../../../actions/customerAction'
import { IndustryType } from '../../../utils/helper'
const { Option } = Select
const AddServiceForm = props => {
  const { form } = props
  const [btnDisable, setBtnDisable] = useState(false)

  useEffect(() => {
    if (props.id !== undefined) {
    }
  }, [props.id || props.title])
  useEffect(() => {
    if (props.visible === false) {
      setBtnDisable(false)
    }
  }, [props.visible])

  const onFinish = value => {}

  return (
    <Footer
      equipmentcheckin={props.equipmentcheckin}
      setVisible={e => props.setVisible(e)}
      visible={props.visible}
      Button={props.Button}
      title={props.title}
      custom={props.custom}
      className={props.className}
      submit={
        <Form.Item>
          <Button
            disabled={btnDisable}
            type='primary'
            loading={props.loading}
            htmlType='submit'
            form={props.formEdit ? `editService` : 'newService'}
            style={{ fontWeight: '600' }}
          >
            {props.formEdit ? 'SAVE' : 'ADD'}
          </Button>
        </Form.Item>
      }
    >
      <Form
        form={form}
        name='register'
        id={props.formEdit ? `editService` : 'newService'}
        onFinish={props.onSubmit}
        scrollToFirstError
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name='service_group_id'
              label='Service Group'
              rules={[
                { required: true, message: 'Please Select Service Group' }
              ]}
            >
              <Select
                virtual={false}
                value={props.selectedServiceGroup}
                allowClear
                disabled={props.formEdit}
                style={{ width: '100%' }}
                placeholder={props.placeholder}
                onChange={props.handleServiceGroupChange}
              >
                {props.serviceGroup &&
                  props.serviceGroup.map(item => {
                    return (
                      <Select.Option
                        key={item.param_value}
                        value={item.param_value}
                      >
                        {item.param_description}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name='service'
              label='SERVICE'
              rules={[{ required: true, message: 'Please Select service' }]}
            >
              <AutoComplete
                disabled={!props.selectedServiceGroup}
                options={props.services}
                onSelect={props.handleServiceChange}
                onSearch={props.handleServiceChange}
                placeholder='Please Enter Service'
              />
              {
              /* <Select
              disabled={!props.selectedServiceGroup}
              virtual={false}
                placeholder="Select Service"
                allowClear
                value={props.selectedService}
                onChange={props.handleServiceChange}
              >
                {props.services &&
                  props.services.map((item) => {
                    return (
                      <Select.Option
                        value={item.param_value}
                        key={item.param_value}
                      >
                        {item.param_description}
                      </Select.Option>
                    );
                  })}
              </Select> */}
            </Form.Item>
          </Col>
          {/* {IndustryType()!=="Food_Grade"&&<Col span={8}><Form.Item
                     labelCol={{ span: 24 }}
                     wrapperCol={{ span: 24 }}
              name="subService"
              label="SUB SERVICE"
              rules={[{ required: [2040,2050,2060,2070].includes(props.selectedServiceGroup) ?false:true ,message: 'Please Select sub service'}]}
            >
              <Select
                   disabled={!props.selectedService}
              virtual={false}
                placeholder="Select Sub Service"
                onChange={props.handleSubServiceChange}
                value={props.selectedSubService}
              >
                {props.subService &&
                  props.subService.map((item) => {
                    return (
                      <Select.Option
                        key={item.param_value}
                        value={item.param_value}
                      >
                        {item.param_description}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item></Col>} */}
          {/* <Col span={8}><Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
              name="category"
              label="CATEGORY"
              rules={[{ required: props.mandatoryHaz }]}
            >
              <Select
                 disabled={!props.selectedService}
              virtual={false}
                placeholder="Select Service Category"
                allowClear
                value={props.haz}
                
              >
                {props?.hazData?.map((item) => {
                    return (
                      <Select.Option
                        key={item.param_value}
                        value={item.param_value}
                      >
                        {item.param_description}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item></Col> */}
          {/* <Col span={8}>
            <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
              label="PRICE"
              name="Price"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Price',
                },
                { type: 'number' },
                {
                  pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                  message: 'Price value should be greater than 0 number',
                },
              ]}
            >
              <InputNumber 
                    style={{width:'100%'}}
                value={props.price}
                onChange={props.handlePriceChange}
                min={0}
                precision={2}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
              label="TAX %"
              name="Tax"
              rules={[
                {
                  required: false,
                  message: 'Please enter tax rate',
                },
                { type: 'number' },
                {
                  pattern: /^[0-9]\d*$/,
                  message: 'Tax value should be greater or equal to 0 number',
                },
              ]}
            >
              <InputNumber
                style={{width:'100%'}}
                value={props.tax}
                onChange={props.handleTaxChange}
                min={0}
                precision={2}
              />
            </Form.Item>
            </Col>
             */}
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name='Remark'
              label='REMARKS'
            >
              <Input.TextArea
                value={props.remark}
                onChange={props.setRemark}
                className='textArea'
                style={{ height: '130px' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Footer>
  )
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  user_status: state.user.user_status
})

export default connect(mapStateToProps, { AddCustomer, EditCustomer })(
  AddServiceForm
)
